import {getDictArrayValIdx,initLanguageLbls,SIMUL_PARAMS_BASE,SIMUl_PARAMS_BASE_LBL,SIMUL_PARAMS_BASE_MIN,SIMUl_PARAMS_BASE_MAX,SIMUl_PARAMS_BASE_VAL_TYPE,LOSS,YEAR_START,YEAR_STOP} from "../Core/SimulParamsBase"
import { globalParams } from "./GlobalParams"

export const HEIGHT = "hauteur"
export const WIND_TURBINE = "marque"
export const WIND_TURBINE_TYPE = "modele"
export const WAKE_EFFECT = "wake_loss"

const _SIMUL_PARAMS_EOL = Object.freeze({
    [LOSS]: [0,["Pertes générales (%)","Global loss (%)"],Number,0,20],
    [YEAR_START]: [2018,["Années","Years"],Number,globalParams.date_range_EOL.start,globalParams.date_range_EOL.stop],
    [YEAR_STOP]: [globalParams.date_range_EOL.stop,["Annees","Years"],Number,globalParams.date_range_EOL.start,globalParams.date_range_EOL.stop],
    [WIND_TURBINE]: [globalParams.defaultTurbine,["Eolienne","Wind turbine"],String,null,null],
    [WIND_TURBINE_TYPE]: [globalParams.defaultTurbineType,["Eolienne_Type","Turbine type"],String,null,null],
    [HEIGHT]: [80,["Hauteur (m)","Height (m)"],Number,0,500],
    [WAKE_EFFECT]: [0,["Effet sillage (%)","Wake loss (%)"],Number,0,20]
})

export const SIMUL_PARAMS_EOL = Object.freeze({...SIMUL_PARAMS_BASE,...getDictArrayValIdx(_SIMUL_PARAMS_EOL,0)})
export const SIMUL_PARAMS_EOL_LBL = Object.freeze({...SIMUl_PARAMS_BASE_LBL,...getDictArrayValIdx(_SIMUL_PARAMS_EOL,1)})
export const SIMUL_PARAMS_EOL_VAL_TYPE = Object.freeze({...SIMUl_PARAMS_BASE_VAL_TYPE,...getDictArrayValIdx(_SIMUL_PARAMS_EOL,2)})
export const SIMUL_PARAMS_EOL_MIN = Object.freeze({...SIMUL_PARAMS_BASE_MIN,...getDictArrayValIdx(_SIMUL_PARAMS_EOL,3)})
export const SIMUL_PARAMS_EOL_MAX = Object.freeze({...SIMUl_PARAMS_BASE_MAX,...getDictArrayValIdx(_SIMUL_PARAMS_EOL,4)})

initLanguageLbls(SIMUL_PARAMS_EOL_LBL)