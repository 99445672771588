import React from "react"
import { trl } from "../Base/Language"
import { globalParams } from "../Core/GlobalParams"
import {
    LOSS,
    IP,
    YEAR_START,
    YEAR_STOP,
    TIMESTEP,
} from "../Core/SimulParamsBase"
import {
    HEIGHT,
    WAKE_EFFECT,
    WIND_TURBINE,
    WIND_TURBINE_TYPE,
} from "../Core/SimulParamsEOL"
import {
    WIND_TURBINE_LIST,
    WIND_TURBINE_TYPE_LIST 
} from "../Core/SimulEOLTurbine"
import {UIGridForm,UIDropDown,UIRangeSlider,UISpinBox,UISlider,UISwitch} from "@edf-r38/react-components"
import {FormWidth,UISingleSiteBaseForm,useParamState,use2KeysParamsState} from "./UISingleSiteBaseForm"

import {Classes,Divider} from "@blueprintjs/core"
import "@blueprintjs/core/lib/css/blueprint.css"

export function UISingleSiteEolForm(props) {
    const [ip, setIP] = useParamState(props,IP)
    const [height, setHeight] = useParamState(props,HEIGHT)
    const [windTurbine, setWindTurbine] = useParamState(props,WIND_TURBINE)
    const [windTurbineT, setWindTurbineT] = useParamState(props,WIND_TURBINE_TYPE)
    const [timeRange, setTimeRange] = use2KeysParamsState(props,YEAR_START,YEAR_STOP)
    const [wakeEffect, setWakeEffect] = useParamState(props,WAKE_EFFECT)
    const [loss, setLoss] = useParamState(props,LOSS)
    const [timestep, setTimestep] = useParamState(props,TIMESTEP)

    return(
        <div className={Classes.DIALOG_BODY} style={{width: FormWidth}}>
            <UISingleSiteBaseForm {...props}/>
            <Divider/>
            <UIGridForm.Grid>
                <UISpinBox 
                    paramKey={trl(IP)} 
                    value={ip} 
                    onChange={(v,vstr) => setIP(vstr)}
                    stepSize={1}
                    minorStepSize={0.1}
                    allowNumericCharactersOnly={true}
                />
                <UISpinBox 
                    paramKey={trl(HEIGHT)}
                    onChange={setHeight} 
                    value={height}
                />
                <UIDropDown 
                    paramKey={trl(WIND_TURBINE)}
                    allowedValues={Object.values(WIND_TURBINE_LIST)}
                    value={windTurbine}
                    onChange={setWindTurbine}
                />
                <UIDropDown
                    paramKey={""}
                    allowedValues={
                        WIND_TURBINE_TYPE_LIST[windTurbine] ?
                            Object.values(WIND_TURBINE_TYPE_LIST[windTurbine]) : []
                    }
                    value={windTurbineT}
                    onChange={setWindTurbineT} 
                />
                <UISlider paramKey={trl(WAKE_EFFECT)} 
                    value={wakeEffect} 
                    min={0} 
                    max={20}
                    labelStepSize={2}
                    stepSize={1}
                    onChange={setWakeEffect} 
                />
                <UISlider paramKey={trl(LOSS)}
                    value={loss}
                    labelStepSize={2}
                    min={0} 
                    max={20} 
                    stepSize={1}
                    onChange={setLoss}
                />
                <UIRangeSlider paramKey={trl(YEAR_START)}
                    value={timeRange} 
                    labelStepSize={3}
                    min={globalParams.date_range_EOL.start} 
                    max={globalParams.date_range_EOL.stop} 
                    stepSize={1}
                    onChange={setTimeRange} 
                />
                <UISwitch
                    paramKey={trl(TIMESTEP)}
                    onChange={setTimestep}
                    value={timestep}
                />
            </UIGridForm.Grid>
        </div>
    )
}