import React, {Component} from "react"
import { Point } from "../Base/Geometry"
import { globalParams } from "../Core/GlobalParams"
import "leaflet/dist/leaflet.css"
import L from "leaflet"
import markerIcon2x from "leaflet/dist/images/marker-icon-2x.png"
import markerIcon from "leaflet/dist/images/marker-icon.png"
import markerShadow from "leaflet/dist/images/marker-shadow.png"
import mask from "../Files/mask_md.geo.json"

/*Workearound to fix broken marker image:
  https://github.com/PaulLeCam/react-leaflet/issues/808"
 */
delete L.Icon.Default.prototype._getIconUrl
L.Icon.Default.mergeOptions({
    iconUrl: markerIcon,
    iconRetinaUrl: markerIcon2x,
    shadowUrl: markerShadow,
})


export class MainMap extends Component {

    constructor(props) {
        super()
        this.map_ = null
        this.id_ = props.id
        this.markerPosChanged = props.markerPosChanged
        this.ismasksel_ = false
        this.workarea_ = globalParams.boundingbox
    }

    isValidPos(pos) {
        return !isNaN(Number(pos[0])) && !isNaN(Number(pos[1]))
    }

    map() {
        return this.map_
    }

    componentDidUpdate(prevProps, ) {
        if ( this.marker_ && prevProps.position !== this.props.position 
      && this.isValidPos(this.props.position) ) {
            this.marker_.setLatLng( this.props.position )
        }
    }
  
    componentDidMount() {
        let pos = this.props.position
        if ( !this.isValidPos(pos) )
            pos = [0,0]

        this.map_= L.map(this.id_, {
            center: pos,
            zoom: 5,
            layers: [
                L.tileLayer("http://{s}.tile.osm.org/{z}/{x}/{y}.png", {
                    attribution:
                  "&copy; <a href=\"http://osm.org/copyright\">OpenStreetMap</a> contributors",
                    minZoom: 5,
                    maxZoom: 20
                }),
            ]
        })

        const mask_style={
            weight: 1,
            color: "#000000",
            fillOpacity: 0.7,
            fillColor: "#000000"
        }
        this.mask_ = L.geoJSON(mask, {style: mask_style,onEachFeature: (feature, layer) => {
            layer.on({
                "mouseover":  () => { this.ismasksel_ = true },
                "mouseout": () => { this.ismasksel_ = false }
            })
        }
        }).addTo(this.map_)

        this.marker_ = L.marker(pos).addTo(this.map_)
        this.map_.on("click", (ev) => this.onMapClick(ev))
    }

    onMapClick(event) {
        if (!this.map_ || this.ismasksel_ )
            return

        const latlng = this.map_.mouseEventToLatLng(event.originalEvent)
        let point = new Point(latlng.lng,latlng.lat)
        point = this.workarea_.getNearestInside( point )
        this.marker_.setLatLng(new L.LatLng(point.y,point.x))
        this.markerPosChanged([point.y,point.x])
    }

    render() {
        return <div id={this.id_} />
    }
}

