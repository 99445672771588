import { useState } from "react"

import { TokenRequest } from "../Base/TokenRequest"

const url = "/Info"

export const useBackendInfo = () => {
    const [JEDI_BACKEND_VERSION, setBackendVersion] = useState("Unknown")
    const [JEDI_API_VERSION, setApiVersion] = useState("Unknown")

    const fetchBackendInfo = async () => {
        try {
            const { backend_version, api_version } = await TokenRequest.fetch(url, {}, "GET")
            setBackendVersion(backend_version)
            setApiVersion(api_version)
        } catch (error) {
            console.error("Error fetching backend info:", error)
            throw error
        }
    }

    return { JEDI_BACKEND_VERSION, JEDI_API_VERSION, fetchBackendInfo }
}