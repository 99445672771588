import React, {useEffect,useRef,useState} from "react"
import {Button,Divider,Intent} from "@blueprintjs/core"
import "@blueprintjs/core/lib/css/blueprint.css"
import { defJEDIKeyLbl, trl } from "../Base/Language"
import PubSub from "../Base/PubSub"
import {changeKey,changeVal} from "../Base/Utils.js"
import {BOTTOM_OCCUPATION, MAX_TILTING, ORIENTATION, SOLAR_PANEL, TILTING, TRACKER} from "../Core/SimulParamsPV"
import {HEIGHT,WIND_TURBINE,WIND_TURBINE_TYPE } from "../Core/SimulParamsEOL"
import {LONGITUDE,LATITUDE,NAME,SITE,YEAR_START,YEAR_STOP, LOSS, TIMESTEP} from "../Core/SimulParamsBase"
import {genSimulName} from "../Core/SimulParamsBase.js"
import {SimulRequest} from "../Core/SimulRequest"
import {FileChecker} from "../Core/SimulFileChecker"
import {ProgressChecker} from "../Core/SimulProgressChecker"
import SimulParams from "../Core/SimulParams"
import {EOL_KEY, PV_KEY, SIM_KEY} from "../Core/SimulProdTypes"
import {UIHCenteredDiv,UIDraggableDialog} from "@edf-r38/react-components"
import {UISimulEolForm,UISimulPVForm} from "./UISimulEnergyForm"
import { fetchTurbineList } from "../Core/SimulEOLTurbine"

const SIM_LAUNCH_KEY = defJEDIKeyLbl("SimLaunch",["Simuler...","Simulate..."])
const SIM_FAILED_KEY = defJEDIKeyLbl("SimFailed", ["La simulation a échoué","Simulation failed"])
const SIM_CANCEL_KEY = defJEDIKeyLbl("SimCancel", ["Annuler la simulation en cours ?","Simulation in progress. Cancel ?"])

export default function UISimulParamsDlg(props) {
    const simulParamsRef = useRef(null)
    const fileCheckerRef = useRef(new FileChecker())
    const progressCheckerRef = useRef(new ProgressChecker())
    function simParams() {
        if (simulParamsRef.current === null) {
            simulParamsRef.current = new SimulParams()
        }
        return simulParamsRef.current
    }

    useEffect( () => {
        (async () => {
            try{
                await fetchTurbineList()
            } catch (error) {
                alert("Could not fetch turbine data from data base")
            }
        })()
    },[])

    const [cursor, setCursor] = useState("pointer")
    const [isrunning, setIsRunning] = useState(false)
    const [isSingleSite, setIsSingleSite] = useState(true)
    const [eolParams, setEolParams] = useState(props.eolParams || simParams().eolParams())
    const [pvParams, setPVParams] = useState(props.pvParams || simParams().pvParams())
    const [eolParamsSet, setEolParamsSet] = useState(props.eolParamsSet || null)
    const [pvParamsSet, setPVParamsSet] = useState(props.pvParamsSet || null)

    const getParams = () => props.isEol ? eolParams : pvParams
    const getParamsSet = () => props.isEol ? eolParamsSet : pvParamsSet

    function setParams(params) {
        if ( props.isEol )
            setEolParams( params )
        else 
            setPVParams( params )
        
        simParams().setParams(params,props.isEol)
    }

    useEffect(()=>{
        const onfound = PubSub.onSimulationFound(url => { 
            setIsRunning(false)
            progressCheckerRef.current.stop()
            changeCursor()
            window.location.assign(url)
        } )
        const onnotfound = PubSub.onSimulationNotFound( () => { 
            setIsRunning(false)
            progressCheckerRef.current.stop()
            changeCursor()
            alert(trl(SIM_FAILED_KEY)) 
        } )
        const onprogchanged = PubSub.onProgressChanged(v => {
            if ( v >= 99.99 && v <= 100.01 ) {
                fileCheckerRef.current.start()
            }
        })
        return () => {
            PubSub.unsubscribe(onfound)
            PubSub.unsubscribe(onnotfound)
            PubSub.unsubscribe(onprogchanged)
        }
    },[])

    useEffect(() => {
        if ( !props.position )
            return

        let params = props.isEol ? {...eolParams} : {...pvParams}
        if ( params[LONGITUDE] !== props.position[1] ||
             params[LATITUDE] !== props.position[0]) {
            params[LONGITUDE] = props.position[1]
            params[LATITUDE] = props.position[0]
            if ( props.isEol )
                setEolParams( params )
            else 
                setPVParams( params )
        }
    },[eolParams,pvParams,props.isEol,props.position])

    useEffect(() => {
        if (isrunning)
            executeSimulation()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isrunning])

    const changeCursor = () => {
        setCursor(prevState => prevState === "wait" ? "pointer" : "wait")
    }

    const getProcessedParamsSet = () => {
        let paramsset = getParamsSet()
        let sites = []
        for ( let [idx,params] of paramsset.entries() ) {
            params = processParamsParams(params)
            let site = params[SITE]
            if (sites.includes(site)) {
                site += "_" + idx
                params[SITE] = site
            }
            sites.push(site)         
        }
        return paramsset
    }

    const getProcessedParams = () => {
        let params = getParams()
        params = processParamsParams(params)
        params[NAME] = genSimulName(props.isEol)
        return params
    }

    const NotANumber = "NA"

    const processParamsParams = (params) => {
        params = convertParamsV1ToV2(params)
        if (!props.isEol) {
            if (params[TRACKER] === true || params[TRACKER] === "TRUE" ||
              params[TRACKER] === "True" || params[TRACKER] === "true") {
                params[TRACKER] = "withTracker"
            } else {
                params[TRACKER] = "noTracker"
            }
            if (params[TRACKER] === "noTracker")
                changeVal(params, NotANumber, "0")
        }
        if (params[SITE].length === 0) {
            params[SITE] = SITE
        }
        params[SITE] = params[SITE].replace(/ /g, "_")
        params[TIMESTEP] = params[TIMESTEP] === "TRUE" || params[TIMESTEP] === true || params[TIMESTEP] === "true" ? true : false
        params[TIMESTEP] = params[TIMESTEP].toString()
        return params
    }

    const convertParamsV1ToV2  = (params) => {
        changeKey(params,"Tracker",TRACKER)
        changeKey(params,"Orientation",ORIENTATION)
        changeKey(params,"Panneau",SOLAR_PANEL)
        changeKey(params,"Inclinaison_max",MAX_TILTING)
        changeKey(params,"Inclinaison",TILTING)
        changeKey(params,"Occupation_sol",BOTTOM_OCCUPATION)
        changeKey(params,"Pertes",LOSS)

        changeKey(params,"date_debut",YEAR_START)
        changeKey(params,"date_fin",YEAR_STOP)
        changeKey(params,"Marque",WIND_TURBINE)
        changeKey(params,"Modele",WIND_TURBINE_TYPE)
        changeKey(params,"Longitudes",LONGITUDE)
        changeKey(params,"Latitudes",LATITUDE)
        changeKey(params,"Hauteurs",HEIGHT)
        changeKey(params,"Noms",SITE)
        return params
    }

    
    const handleSimulClick = () => {
        if ( isrunning ) {
            if (window.confirm(trl(SIM_CANCEL_KEY))) {
                progressCheckerRef.current.stop()
                fileCheckerRef.current.stop()
                setIsRunning(false)
                changeCursor()
                PubSub.publishSimulationStopped()
            }
        }
        else {
            setIsRunning(true)
            PubSub.publishSimulationStarted()
        }
    }

    const executeSimulation = async () => {
        let params = isSingleSite ? getProcessedParams() : getProcessedParamsSet()
        if ( !params )
            return

        const req = new SimulRequest(params)
        if (isSingleSite)
            simParams().save()
        
        changeCursor()
        req.execute(props.isEol,isSingleSite) // await not compatible with apivalo
        if (!isrunning)
            return
        
        const file_name = isSingleSite ? params[NAME] : params[0][NAME]
        fileCheckerRef.current.init(file_name)
        progressCheckerRef.current.start(file_name)
    }

    function doSimulKeysParamsChange(keyvals) {
        let params = {...getParams()}
        for (const [key, val] of Object.entries(keyvals)) {
            params[key] = val
        }
        setParams( params )
    }

    function doSimulKeyParamsChange(key, value) {
        let params = {...getParams()}
        params[key] = value
        setParams( params )

        if ( key === LONGITUDE || key === LATITUDE )
            props.onPosChange([params[LATITUDE],params[LONGITUDE]])
    }

    async function doSimulParamsSetChange(paramsset) {
        if ( props.isEol )
            setEolParamsSet( paramsset )
        else 
            setPVParamsSet( paramsset )
    }

    function onSetSingleSite(issingle) {
        setIsSingleSite(issingle)
    }

    function getTitle() {
        let ttl = trl(SIM_KEY) + ": "
        ttl += props.isEol? trl(EOL_KEY) : trl(PV_KEY)
        return ttl
    }

    return (
        <UIDraggableDialog style={{minWidth: isSingleSite ? "auto" : "550px"}}
            className={"user-preferences-dialog"}
            icon={"settings"}
            title={getTitle()}
            {...props}
        >
            { props.isEol ? 
                <UISimulEolForm 
                    {...eolParams} 
                    onChange={doSimulKeyParamsChange}
                    on2KeysChange={doSimulKeysParamsChange}
                    onSetSingleSite={onSetSingleSite}
                    onSetChange={doSimulParamsSetChange}
                /> 
                : 
                <UISimulPVForm 
                    {...pvParams}
                    onChange={doSimulKeyParamsChange}
                    on2KeysChange={doSimulKeysParamsChange}
                    onSetSingleSite={onSetSingleSite}
                    onSetChange={doSimulParamsSetChange}
                />
            }
            <Divider/>
            <UIHCenteredDiv>
                <Button onClick={handleSimulClick}
                    intent={isrunning? Intent.DANGER : props.isEol ? Intent.PRIMARY : Intent.WARNING} 
                    style={{width : isrunning ?"250px" : "100px", cursor: cursor, marginBottom:"10px"}}>
                    {isrunning ? <b>{trl(SIM_CANCEL_KEY)}</b> : <b>{trl(SIM_LAUNCH_KEY)}</b> }
                </Button>
            </UIHCenteredDiv>
        </UIDraggableDialog>
    )
}


