import PubSubLib from "pubsub-js"

const PROGRESS_CHANGED = "simulation_progress_changed"
const SIMUL_STATUS_CHANGED = "simulation_status_changed"
const SIMUL_STARTED = "simulation_started"
const SIMUL_STOPPED = "simulation_stopped"
const SIMULATION_FOUND = "simulation_found"
const SIMULATION_NOT_FOUND = "simulation_not_found"

const PubSub = {

    publishSimulationFound: function(event) {
        this.publish(SIMULATION_FOUND, event)
    },

    onSimulationFound: function(subscriber) {
        this.subscribe(SIMULATION_FOUND, subscriber)
    },

    publishSimulationNotFound: function(event) {
        this.publish(SIMULATION_NOT_FOUND, event)
    },

    onSimulationNotFound: function(subscriber) {
        this.subscribe(SIMULATION_NOT_FOUND, subscriber)
    },

    publishProgressChanged: function(event) {
        this.publish(PROGRESS_CHANGED, event)
    },

    onProgressChanged: function(subscriber) {
        this.subscribe(PROGRESS_CHANGED, subscriber)
    },

    publishSimulationStopped: function(event) {
        this.publish(SIMUL_STOPPED, event)
    },

    onSimulationStopped: function(subscriber) {
        this.subscribe(SIMUL_STOPPED, subscriber)
    },

    publishSimulationStarted: function(event) {
        this.publish(SIMUL_STARTED, event)
    },

    onSimulationStarted: function(subscriber) {
        this.subscribe(SIMUL_STARTED, subscriber)
    },

    publishSimulStatusChanged: function(event) {
        this.publish(SIMUL_STATUS_CHANGED, event)
    },

    onSimulStatusChanged: function(subscriber) {
        this.subscribe(SIMUL_STATUS_CHANGED, subscriber)
    },

    publish: function(topic, event) {
        PubSubLib.publish(topic, event)
    },

    subscribe: function(topic, subscriber) {
        PubSubLib.subscribe(topic, (t, e) => {
            subscriber.call(null, e)
        })
    },

    unsubscribe: function(token) {
        PubSubLib.unsubscribe(token)
    }
}

export default PubSub
