import { handleApivaloRequest } from "../Apivalo/Main"
import { getToken } from "./AuthStore"

export class TokenRequest {

    static async fetch(url,payload,type) {
        const token = getToken()
        console.log(token)

        try {
            return await handleApivaloRequest(url, payload, type, token)      
        } catch (error) {
            console.error("Error fetching backend info:", error)
            throw error
        }
    }
}
