import { UserHistory } from "./UserHistory"

const LANGUAGE_STORAGE_KEY = "language"

export const FRENCH= "French"
export const ENGLISH = "English"

export default class LanguageState {
    constructor() {
        this._state = FRENCH
        this._userHistory = new UserHistory(LANGUAGE_STORAGE_KEY)
        this.load()
    }
   
    isFrench() {
        return this.getState() === FRENCH
    }

    getOppositeState() {
        return this.isFrench() ? ENGLISH : FRENCH
    }

    getState() {
        return this._state
    }

    setState(state) {
        if (state) {
            this._state = state
            this.save()
        }
    }

    load() {
        const state = this._userHistory.get(LANGUAGE_STORAGE_KEY)
        if (state)
            this._state = state
    }
    
    save() {
        this._userHistory.set(LANGUAGE_STORAGE_KEY, this._state)
    }
}

export const language = new LanguageState()

const dictionaryList = {"fr": 0, "en": 1}
const LanguageContext = {}

export const defJEDIKeyLbl = (key,val) => {
    LanguageContext[key] = val
    return key
}

export const trl = key => LanguageContext[key]? LanguageContext[key][language.isFrench() ? dictionaryList.fr : dictionaryList.en] : key
