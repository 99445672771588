import {getDictArrayValIdx,initLanguageLbls,NAME,SIMUL_PARAMS_BASE,SIMUl_PARAMS_BASE_LBL,SIMUL_PARAMS_BASE_MIN,SIMUl_PARAMS_BASE_MAX,SIMUl_PARAMS_BASE_VAL_TYPE,LOSS,YEAR_STOP,YEAR_START} from "../Core/SimulParamsBase"
import { globalParams } from "./GlobalParams"

export const BOTTOM_OCCUPATION = "occupationSol"
export const MAX_IP = "PMax"
export const MAX_TILTING = "inclinaisonMax"
export const ORIENTATION = "azimuth"
export const SOLAR_PANEL = "techno"
export const TILTING = "inclinaison"
export const TRACKER = "tracker"

export const SOLAR_PANEL_LIST = Object.freeze({
    SiMono: "SiMono",
    SiPoly: "SiPoly",
    CdTe: "CdTe", 
    CiGs: "CiGs",
    Advanced: "Advanced"
})

const _SIMUL_PARAMS_PV = Object.freeze({
    [NAME]: ["SIMULATION_PV",["",""],String,null,null],
    [LOSS]: [0,["Pertes Générales(%)","Global Loss (%)"],Number,0,100],
    [MAX_IP]: [1,[MAX_IP,"Max Power"],Number,0,10000],
    [SOLAR_PANEL]: [Object.values(SOLAR_PANEL_LIST)[0],["Panneau","Techno"],String,null,null],
    [TRACKER]: [false,[TRACKER,TRACKER],Boolean,null,null],
    [ORIENTATION]: [0,["Orientation (°S)","Orientation (°S)"],Number,0,180],
    [MAX_TILTING]: [60,["Inclinaison max (°)","Max tilting (°)"],Number,0,180],
    [TILTING]: [30,["Inclinaison (°)","Tilting (°)"],Number,0,180],
    [YEAR_START]: [2018,["Années","Years"],Number,globalParams.date_range_PV.start,globalParams.date_range_PV.stop],
    [YEAR_STOP]: [globalParams.date_range_PV.stop,["Années","Years"],Number,globalParams.date_range_PV.start,globalParams.date_range_PV.stop],
    [BOTTOM_OCCUPATION]: [10,["Occupation sol (%)","Ground occupation (%)"],Number,0,180]
})

export const SIMUL_PARAMS_PV = Object.freeze({...SIMUL_PARAMS_BASE,...getDictArrayValIdx(_SIMUL_PARAMS_PV,0)})
export const SIMUL_PARAMS_PV_LBL = Object.freeze({...SIMUl_PARAMS_BASE_LBL,...getDictArrayValIdx(_SIMUL_PARAMS_PV,1)})
export const SIMUL_PARAMS_PV_VAL_TYPE = Object.freeze({...SIMUl_PARAMS_BASE_VAL_TYPE,...getDictArrayValIdx(_SIMUL_PARAMS_PV,2)})
export const SIMUL_PARAMS_PV_MIN = Object.freeze({...SIMUL_PARAMS_BASE_MIN,...getDictArrayValIdx(_SIMUL_PARAMS_PV,3)})
export const SIMUL_PARAMS_PV_MAX = Object.freeze({...SIMUl_PARAMS_BASE_MAX,...getDictArrayValIdx(_SIMUL_PARAMS_PV,4)})

initLanguageLbls(SIMUL_PARAMS_PV_LBL)
