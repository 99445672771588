import {create} from "zustand"
import TokenFetcher from "./TokenFetcher"
import { defJEDIKeyLbl, trl } from "./Language"

let token = null

const AUTH_IN_PROGRESS_KEY = defJEDIKeyLbl( "AuthInProgress", ["Authentification déjà en cours", "Authentication already in progress"] )
const AUTH_FAILED_KEY = defJEDIKeyLbl( "AuthFailedKey", ["Echec de l'authentification","Failed to authenticate"] )

const stateCreator = (set, get) => {
    const tokenFetcher = new TokenFetcher()

    tokenFetcher.init()
        .then( isok => { if (isok) setLoggedIn() } )
        .catch(() => {
            setLoggedOut()
        })

    function setLoggedOut() {
        console.log("Logged out")
        set({ _username: null, _accessToken: null, _isInProgress: false })
    }

    function setLoggedIn() {
        console.log("Logged in")
        const _username = tokenFetcher.getUsername()
        const _accessToken = tokenFetcher.getToken()
        token = _accessToken
        set(() => ({
            _username,
            _accessToken,
            _isInProgress: false
        }))
    }

    return {
        _username: null,
        _token: null,
        _isInProgress: false,

        getUsername: () => get()._username,
        getToken: () => get()._token,
        isInProgress: () => get()._isInProgress,
        isAuthenticated: () => get()._username !== null,
        login: (username, password, isAgent) => {
            if (get()._isInProgress) {
                throw new Error(trl(AUTH_IN_PROGRESS_KEY))
            }
            set({_isInProgress: true})
            return tokenFetcher.fetch( username, password, isAgent)
                .then(setLoggedIn)
                .catch(() => {
                    setLoggedOut()
                    throw new Error(trl(AUTH_FAILED_KEY))
                })
        },

        logout: () => {
            tokenFetcher.erase()
            setLoggedOut()
        }
    }
}

export function getToken() {
    return token
}

export const useAuthStore = create(stateCreator)
