import React, {useCallback} from "react"
import {Button, Classes, Dialog, Divider} from "@blueprintjs/core"
import {UIHDiv} from "@edf-r38/react-components"
import logo from "../../src/Files/jedi_logo.png"

const { REACT_APP_JEDI_VERSION } = process.env

export default function UIAboutDlg(props) {
    const doClose = useCallback(() => props.onClose(), [props])

    return (
        <Dialog className="about-dialog" icon="annotation" title="About"
            isOpen={props.isVisible}
            onClose={props.onClose}
        >
            <div className={Classes.DIALOG_BODY}>
                <UIHDiv>
                    <img 
                        className="JEDIlogo" 
                        alt="The logo" 
                        src={logo} 
                        style={{  
                            marginLeft: "auto",
                            marginRight: "auto",
                            width:200, height:200
                        }} 
                    />
                </UIHDiv>
                <Divider/>
                <UIHDiv> 
                    <label style={{
                        textAlign:"center",
                        marginLeft: "auto",
                        marginRight: "auto",
                        fontSize:16,width:200}}>
                Jedi version {REACT_APP_JEDI_VERSION}
                    </label> 
                </UIHDiv>
            </div>
            <div className={Classes.DIALOG_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                    <Button onClick={doClose}>Close</Button>
                </div>
            </div>
        </Dialog>
    )
}