import PubSub from "../Base/PubSub"
//import { TokenRequest } from "../Base/TokenRequest"
import { globalParams } from "./GlobalParams"

const url_to_backend = globalParams.backendBaseUrl + "/cgi/s3_get.cgi" + "/IsFilePresent?filename="
const simul_time_interval = globalParams.simulCheckInterval*1000
const simul_max_try = 1000

export class FileChecker{
    constructor(payload) {
        this._url = url_to_backend
        this._payload = payload
        this._isactive = false
        this._filename = null
    }

    init(fnm) {
        this._filename = fnm + ".zip"
    }

    start() {
        this._isactive = true
        this.run()
    }

    stop() {
        this._isactive = false
        clearInterval(this._interval)
    }

    async run() {
        let time = 0
        this._interval = setInterval(async ()=> { 
            if ( !this._isactive )
                return

            if (time <= simul_max_try) { 
                const res = await this.execute()
                if ( res ) {
                    this.stop()
                    clearInterval(this._interval)
                    const url = res.url.startsWith(globalParams.backendBaseUrl) ? 
                        res.url : globalParams.backendBaseUrl + res.url
                    PubSub.publishSimulationFound(url)
                }
                time++
            }
            else {
                console.log(`Filename ${this._filename} not found`)
                this.stop()
                PubSub.publishSimulationNotFound()
            }
        }, simul_time_interval)
    }

    async execute() {
        const url = this._url + this._filename
        console.log(`checking for ${url}`)
        return fetch( url, { 
            method: "GET",
        })
            .then(
                r=>r.json()
            )
            .catch(
                () =>null
            )
    }
}

