const config = require("./config") 

const { apivaloUrl } = config

function transform2Apivalo(apiUrl, payload, isGet) {
    const transformedPayload = {
        appName: "JEDI_Test2",
        apiPath: `${apiUrl}`,
        urlParams: [],
        fileParameterName: "",
        body: isGet ? null : JSON.stringify(payload),
        httpMethod: isGet ? "GET" : "POST",
        headerParams: [{ key: "Content-Type", value: "application/json" }]
    }
    return transformedPayload
}

export async function handleApivaloRequest(apiUrl, payload, method, token) { 
    console.log(token)
    const headers = {
        "HTTP_X_AUTHORIZATION": `Bearer ${token}`,
        "X-Authorization": `Bearer ${token}`,
        "Content-Type": "application/json"
    }
    const apivaloPayload = transform2Apivalo(apiUrl, payload, method === "GET")
    console.log(apivaloPayload)
    try {
        const response = await fetch(apivaloUrl, {
            method: "POST",
            mode: "cors",
            body: JSON.stringify(apivaloPayload),
            headers: headers
        })
        if (!response.ok) {
            throw new Error(`Error forwarding request: ${response.statusText}`)
        }
        const ret = await response.json()
        return await JSON.parse(ret.responseBody)
    } catch (error) {
        throw new Error(`Error forwarding request: ${error.message}`)
    }
}
