export class Point {
    constructor(x=0,y=0) {
        this.x = x
        this.y = y
    }
}

export class Rectangle {
    constructor(l=0,t=0,r=0,b=0) {
        this.topleft = new Point(l,t) 
        this.bottomright = new Point(r,b)
    }

    left() { return this.topleft.x }
    
    top() { return this.topleft.y }
    
    right() { return this.bottomright.x }
    
    bottom() { return this.bottomright.y }

    revX() { return this.left() > this.right() }

    revY() { return this.bottom() > this.top() }

    width() { 
        return this.revX() ? this.left()-this.right() 
            : this.right()-this.left() 
    }

    height() { 
        return this.revY() ? this.bottom()-this.top() 
            : this.top()-this.bottom() 
    }

    isXInside(x) {
        return this.revX() ? this.left() >= x && x >= this.right()
            : this.left() <= x && x <= this.right()
    }

    isYInside(y) {
        return this.revY() ? this.bottom() >= y && y >= this.top()
            : this.bottom() <= y && y <= this.top()
    }
                        
    isInside( pt ) {
        return this.isXInside(pt.x) && this.isYInside(pt.y)
    }

    getNearestXBorder(x) {
        if ( Math.abs(this.left()-x) > Math.abs(this.right()-x) )
            return this.revX() ? this.left() : this.right()
        else
            return this.revX() ? this.right() : this.left()
    }

    getNearestYBorder(y) {
        if ( Math.abs(this.bottom()-y) > Math.abs(this.top()-y) )
            return this.revY() ? this.bottom() : this.top()
        else
            return this.revY() ? this.top() : this.bottom()
    }

    getNearestInside( pt ) {
        const isX = this.isXInside(pt.x)
        const isY = this.isYInside(pt.y)
        if ( !isX || !isY ) {
            if ( isX ) {
                pt.y = this.getNearestYBorder(pt.y)
            }
            else {
                pt.x = this.getNearestXBorder(pt.x)
            }
        }
        return pt
    }
}