import { Rectangle } from "../Base/Geometry"
import { defJEDIKeyLbl } from "../Base/Language"
import { Range } from "../Base/Range"
import config from "../Files/config.json"

export const NB_CPU = "nb_cpus"
export const CPU_LBL = ["Nombre de CPU(s) à utiliser","CPU number to use"]
defJEDIKeyLbl(NB_CPU, CPU_LBL)

export class Params {
    constructor() {
        this.backendBaseUrl = config ? config.server_base_url : "http://localhost:3000/"
        this.simulCheckInterval = config ? config.simul_check_interval : 1
        this.nbCPU = config ? config.default_nb_cpus : 4
        this.defaultLatLon= [47.5, 0]
        this.defaultTurbine = "Enercon"
        this.defaultTurbineType = "E30/200"
        this.boundingbox= new Rectangle(-10.5,  58.5, 18.5, 35.5 )
        this.date_range_PV= new Range(2012,2023)
        this.date_range_EOL= new Range(2000,2023)
    }
}

const par = new Params()
export const globalParams = { "nbCPU": 8, ...Object.freeze(par) }
