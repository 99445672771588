import React, { useEffect, useState } from "react"

import {globalParams} from "../Core/GlobalParams"
import {LONGITUDE,LATITUDE,SITE,} from "../Core/SimulParamsBase"
import {UIGridForm} from "@edf-r38/react-components"
import {UILatLonForm} from "./UILonLatForm"

import {Classes,InputGroup} from "@blueprintjs/core"
import "@blueprintjs/core/lib/css/blueprint.css"

export const FormWidth = 410 //TODO should be dynamic

export function useParamState(props,key) {
    const [val, setVal] = useState(props[key])
    const setAndShareWithParent = (v,doshare=true) => {
        setVal(v)
        if (doshare)
            props.onChange(key,v)
    }
    return [val, setAndShareWithParent]
}

export function use2KeysParamsState(props,key1,key2) {
    const [val, setVal] = useState([props[key1],props[key2]])
    const setAndShareWithParent = (v) => {
        setVal(v)
        props.on2KeysChange({[key1]: v[0], [key2]: v[1] })
    }
    return [val, setAndShareWithParent]
}

export function UISingleSiteBaseForm(props) {
    const [initLon,initLat]=[props[LONGITUDE],props[LATITUDE]]

    const [site, setSite] = useParamState(props,SITE)
    const [lon, setLon] = useParamState(props,LONGITUDE)
    const [lat, setLat] = useParamState(props,LATITUDE)
   
    useEffect(() => {
        setLon(initLon,false)
        setLat(initLat,false)
    }, [initLon,initLat,setLon,setLat])
    
    function handleSiteChange(event) {
        let newValue = event.target.value
        setSite(newValue)
    }
    
    return(
        <div className={Classes.DIALOG_BODY}>
            <UIGridForm.Grid>
                <UIGridForm.Label>Site</UIGridForm.Label>
                <InputGroup value={site} onChange={handleSiteChange}/>
                <UILatLonForm 
                    latlon={[lat,lon]}
                    boundingbox={globalParams.boundingbox}
                    onLonChange={setLon} 
                    onLatChange={setLat}
                />
            </UIGridForm.Grid>
        </div>
    )
}