import React from "react"

import { trl } from "../Base/Language"
import { globalParams } from "../Core/GlobalParams"
import {
    LOSS,
    IP,
    YEAR_START,
    YEAR_STOP,
    TIMESTEP,
} from "../Core/SimulParamsBase"
import {
    BOTTOM_OCCUPATION,
    MAX_IP,
    MAX_TILTING,
    ORIENTATION,SOLAR_PANEL,
    SOLAR_PANEL_LIST,
    TILTING,
    TRACKER } from "../Core/SimulParamsPV"
import {UIDropDown,UIGridForm,UIRangeSlider,UISpinBox,UISwitch} from "@edf-r38/react-components"
import {FormWidth,UISingleSiteBaseForm,useParamState,use2KeysParamsState} from "./UISingleSiteBaseForm"

import {Classes,Divider} from "@blueprintjs/core"
import "@blueprintjs/core/lib/css/blueprint.css"

export function UISingleSitePVForm(props) {
    const [ip, setIP] = useParamState(props,IP)
    const [tilting, setTilting] = useParamState(props,TILTING)
    const [maxIP, setMaxIP] = useParamState(props,MAX_IP)
    const [solarPanel, setSolarPanel] = useParamState(props,SOLAR_PANEL)
    const [tracker, setTracker] = useParamState(props,TRACKER)
    const [orientation, setOrientation] = useParamState(props,ORIENTATION)
    const [loss, setLoss] = useParamState(props,LOSS)
    const [maxTilting, setMaxTilting] = useParamState(props,MAX_TILTING)
    const [bottomOccupation, setBottomOccupation] = useParamState(props,BOTTOM_OCCUPATION)
    const [timeRange, setTimeRange] = use2KeysParamsState(props,YEAR_START,YEAR_STOP)
    const [timestep, setTimestep] = useParamState(props,TIMESTEP)

    return(
        <div className={Classes.DIALOG_BODY} style={{width: FormWidth}}>
            <UISingleSiteBaseForm {...props}/>
            <Divider/>
            <UIGridForm.Grid>
                <UISpinBox 
                    paramKey={trl(IP)} 
                    value={ip} 
                    onChange={(v,vstr) => setIP(vstr)}
                />
                <UISpinBox
                    paramKey={trl(MAX_IP)}
                    value={maxIP}
                    onChange={(v,vstr) => setMaxIP(vstr)}
                />
                <UISpinBox
                    paramKey={trl(TILTING)}
                    onChange={setTilting}
                    value={tilting}
                />
                <UISpinBox
                    paramKey={trl(ORIENTATION)}
                    onChange={setOrientation}
                    value={orientation}
                />
                <UISpinBox
                    paramKey={trl(LOSS)}
                    onChange={setLoss}
                    value={loss}
                />
                <UIDropDown
                    paramKey={trl(SOLAR_PANEL)}
                    allowedValues={Object.keys(SOLAR_PANEL_LIST)}
                    value={solarPanel}
                    onChange={setSolarPanel}
                />
                <UISwitch
                    paramKey={trl(TRACKER)}
                    onChange={setTracker}
                    value={tracker}
                />
                <UISpinBox
                    paramKey={trl(MAX_TILTING)}
                    onChange={setMaxTilting}
                    value={maxTilting}
                />
                <UISpinBox
                    paramKey={trl(BOTTOM_OCCUPATION)}
                    onChange={setBottomOccupation}
                    value={bottomOccupation}
                />
                <UIRangeSlider paramKey={trl(YEAR_START)}
                    value={timeRange} 
                    labelStepSize={3}
                    min={globalParams.date_range_PV.start} 
                    max={globalParams.date_range_PV.stop} 
                    stepSize={1}
                    onChange={setTimeRange} 
                />
                <UISwitch
                    paramKey={trl(TIMESTEP)}
                    onChange={setTimestep}
                    value={timestep}
                />
            </UIGridForm.Grid>
        </div>
    )
}