import { globalParams } from "./GlobalParams"
import { defJEDIKeyLbl } from "../Base/Language"

export const NAME = "nom"
export const SITE = "site" 
export const LONGITUDE = "longitude"
export const LATITUDE = "latitude"
export const LOSS = "general_loss"
export const IP = "PI"
export const TIMESTEP = "pas_temps_15"
export const YEAR_START = "annee_debut"
export const YEAR_STOP = "annee_fin"

export const SIMUL_STATUS = Object.freeze({    
    NOT_STARTED: "Not_Started",
    STARTED: "Started",
    FINISHED: "Finished",
    FAILED: "Failed"
})

// Format : [key] : [default value, [label(fr,en)], type, min_val, max_val]
const _SIMUL_PARAMS_BASE = Object.freeze({
    [NAME]: ["SIMULATION_EOLE",["",""],String,null,null],
    [SITE]: ["","site",String,null,null],
    [LONGITUDE]: [globalParams.defaultLatLon[1],["Lon°E","Lon°E"],Number,globalParams.boundingbox.left(),globalParams.boundingbox.right()],
    [LATITUDE]: [globalParams.defaultLatLon[0],["Lat°N","Lat°N"],Number,globalParams.boundingbox.top(),globalParams.boundingbox.bottom()],
    [IP]: [1,["PI","IP"],Number,0,1000000],
    [TIMESTEP]: [false,["Pas temps 15 min","Timestep 15 min"],Boolean,null,null],
    [YEAR_START]: [globalParams.date_range_EOL.start,["Années","Years"],Number,globalParams.date_range_EOL.start,globalParams.date_range_EOL.stop],
    [YEAR_STOP]: [globalParams.date_range_EOL.stop,["Annees","Years"],Number,globalParams.date_range_EOL.start,globalParams.date_range_EOL.stop]
})

export function getDictArrayValIdx(dict,idx) {
    let newdict = {...dict}
    Object.entries(newdict).map(([k,v]) => newdict[k] = v[idx])
    return Object.freeze(newdict)
}

export const SIMUL_PARAMS_BASE = getDictArrayValIdx(_SIMUL_PARAMS_BASE,0)
export const SIMUl_PARAMS_BASE_LBL = getDictArrayValIdx(_SIMUL_PARAMS_BASE,1)
export const SIMUl_PARAMS_BASE_VAL_TYPE = getDictArrayValIdx(_SIMUL_PARAMS_BASE,2)
export const SIMUL_PARAMS_BASE_MIN =  getDictArrayValIdx(_SIMUL_PARAMS_BASE,3)
export const SIMUl_PARAMS_BASE_MAX = getDictArrayValIdx(_SIMUL_PARAMS_BASE,4)

export function initLanguageLbls(dict) {
    Object.entries(dict).forEach( ([k,v]) => defJEDIKeyLbl(k,v) ) 
}

export function genSimulName(iseol) {
    let prefix = iseol ? "EOL" : "PV"
    const currentDate = new Date()
    const timestamp = currentDate.getTime()
    return `${prefix}_Simulation_${timestamp}`
}
