import "./App.css"
import {Classes,FocusStyleManager} from "@blueprintjs/core"
import React, {useEffect,useState} from "react"
import {theme} from "../Base/Theme"
import {language} from "../Base/Language"
import {MainMap} from "../Leaflet/LMainMap"
import {globalParams} from "../Core/GlobalParams"
import {useBackendInfo} from "../Core/InfoVersion"
import {UIStatusBar,UIVDivGrow} from "@edf-r38/react-components"
import UIMainToolBar from "./UIMainToolBar"

const { REACT_APP_JEDI_VERSION } = process.env

FocusStyleManager.onlyShowFocusOnTabs()
const currentYear = new Date().getFullYear()

function App() {
    const [latlon,setLatlon] = useState(globalParams.defaultLatLon)
    const [,setThemeState] = useState(theme.getState())
    const [,setLanguageState] = useState(language.getState())
    const { JEDI_API_VERSION, JEDI_BACKEND_VERSION, fetchBackendInfo } = useBackendInfo()

    useEffect( () => {
        (async () => {
            setTheme(theme.getState())
            setLanguage(language.getState())

            try{
                await fetchBackendInfo()
            } catch (error) {
                console.log("could not fetch version info from the api/backend")
            }
        })()
    },[])

    function toggleTheme() {
        setTheme(theme.getOppositeState())
    }

    function toggleLanguage() {
        setLanguage(language.getOppositeState())
    }

    function setTheme(state) {
        theme.setState(state)
        setThemeState(state)
        document.body.style.backgroundColor = theme.getColor()
        if (theme.isDark()) {
            document.body.classList.add(Classes.DARK)
        } else {
            document.body.classList.remove(Classes.DARK)
        }
    }

    function setLanguage(state) {
        language.setState(state)
        setLanguageState(state)
    }

    return (
        <UIVDivGrow>
            <UIMainToolBar
                onThemeToggle={toggleTheme}
                onLanguageToggle={toggleLanguage}
                position={latlon}
                onPosChange={setLatlon}
            />
            <MainMap 
                id={"map"} 
                position={latlon} 
                markerPosChanged={setLatlon}
            />
            <UIStatusBar msg={`EDF R&D 2022-${currentYear} \
                    / frontend version: ${REACT_APP_JEDI_VERSION} \
                    / api version: ${JEDI_API_VERSION}
                    / backend version: ${JEDI_BACKEND_VERSION}`
            }/>
        </UIVDivGrow>
    )
}

export default App
