export function deepSeal(object) {
    const props = Object.getOwnPropertyNames(object)
    props.forEach((key) => {
        const o = object[key]
        if (o && typeof(o) === "object") 
            deepSeal(o)
    })
    return Object.seal(object)
}

export function getKeyByValue(object, value) {
    return Object.keys(object).find(key => object[key] === value)
}

export function changeKey(object,old_key,new_key) {
    if ( old_key in object ) {
        Object.defineProperty(object, new_key,
            Object.getOwnPropertyDescriptor(object, old_key))
        delete object[old_key]
    }
}

export function changeVal(object,old_val,newval) {
    Object.keys(object).map( (k,) => { 
        return object[k] === old_val ? object[k] = newval : object[k]
    }) 
}

export function ensureValInside(val,start,stop) {
    if ( typeof val != "number" )
        return val

    if ( val == null || isNaN(val) ) {
        return start
    }
    if (stop < start && start !== null && stop !== null) { 
        [start,stop] = [stop,start]
    }
    if ( val < start && start !== null) val = start
    if ( val > stop && stop !== null ) val = stop
    return val
}
