
export class Range {
    constructor(start,stop) {
        this.start = start
        this.stop = stop
    }

    width() {
        return this.stop - this.start
    }

    isValInside( val ) {
        return val >= this.start && val <= this.stop
    }

    isInside(rg) {
        return rg.isValInside(this.start) && rg.isValInside(this.stop)
    }

    includeVal( val ) {
        if ( val < this.start )
            this.start = val
        if ( val > this.stop )
            this.stop = val
    }

    includeRange( rg ) {
        this.includeVal( rg.start() )
        this.includeVal( rg.stop() )
    }

    nearest( val ) {
        return val < this.start ? this.start :
            val > this.stop  ? this.stop : val
    }
}

