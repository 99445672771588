import React, { useState } from "react"

import { defJEDIKeyLbl, trl } from "../Base/Language"
import { UIMultiSiteBaseForm } from "./UIMultiSiteBaseForm"
import { UISingleSiteEolForm } from "./UISingleSiteEolForm"
import { UISingleSitePVForm } from "./UISingleSitePVForm"

import {Intent,Tab,Tabs} from "@blueprintjs/core"
import "@blueprintjs/core/lib/css/blueprint.css"

const SINGLE_SITE_KEY = defJEDIKeyLbl("SingleSiteKey",["Site unique","Single site"])
const MULTI_SITE_KEY = defJEDIKeyLbl("MultiSiteKey",["Multi-sites","Multi-site"])

const singleSiteKey = "SingleSite"

function UISimulEnergyForm(props) {
    const [selectedTabId,setSelectedTabId] = useState(singleSiteKey)
    const panel =  props.iseol ? <UISingleSiteEolForm {...props}/>
        : <UISingleSitePVForm {...props}/>
                               
    const onSelectedTabId = (id) => {
        setSelectedTabId(id)
        props.onSetSingleSite(id === singleSiteKey)
    }   

    return (
        <Tabs key={"tab"}
            intent={Intent.WARNING} 
            vertical={false} 
            id="SiteType"
            onChange={onSelectedTabId} 
            selectedTabId={selectedTabId}>
            <Tab id="SingleSite"
                title={trl(SINGLE_SITE_KEY)}
                panel={panel}
            />
            <Tab id="MultiSite" 
                title={trl(MULTI_SITE_KEY)} 
                panel={<UIMultiSiteBaseForm {...props}/>}
            />
            <Tabs.Expander />
        </Tabs>
    )
}

export function UISimulEolForm(props) {
    return <UISimulEnergyForm {...props} iseol={true}/>
} 

export function UISimulPVForm(props) {
    return <UISimulEnergyForm {...props} iseol={false}/>
}