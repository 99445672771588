import React, { useState, useEffect } from "react"
import {Button,ButtonGroup,Divider,Intent} from "@blueprintjs/core"
import {theme} from "../Base/Theme"
import {language} from "../Base/Language"
import { defJEDIKeyLbl, trl } from "../Base/Language"
import { useAuthStore } from "../Base/AuthStore"
import PubSub from "../Base/PubSub"
import { EOL_KEY, PV_KEY, SIM_KEY } from "../Core/SimulProdTypes"
import {UIAuthDialog,UIBarButton,UIHorToolBarDiv,UIProgressBar,UISpacerDiv,UIHorTopToolBarDiv} from "@edf-r38/react-components"
import UIAboutDlg from "../uiCore/UIAboutDlg"
import UISimulParamsDlg from "../uiCore/UISimulParamsDlg"
import { GBIcon, FRIcon } from "../Files/FlagIcons"

const USER_CONNECT_KEY = defJEDIKeyLbl("UserConnect",
    ["Vous devez vous connecter pour pouvoir lancer une simulation.\n Se connecter?",
        "You must be logged in to lauch a simulation.\n Log in?"]
)

export default function UIMainToolBar(props) {
    const authstore = useAuthStore()
    const isAuthenticated = authstore.isAuthenticated()
    const [isAboutDlgVisible,setAboutDlgVisible] = useState(false)
    const [isAuthDlgVisible, setAuthDlgVisible] = useState(false)
    const [isSimulDlgVisible,setSimulDlgVisible] = useState(false)
    const [isEol,setIsEol] = useState(true)
    const [progress,setProgress] = useState(0)

    useEffect(()=>{
        const onprogchanged = PubSub.onProgressChanged(v => {
            setProgress(v)
        } )
        return () => {
            PubSub.unsubscribe(onprogchanged)
        }
    },[])

    function doCheckLoggedIn() {
        if  ( isAuthenticated ) {
            return true
        }
        else {
            const res = window.confirm(trl(USER_CONNECT_KEY))
            if ( res ) {
                setAuthDlgVisible(true)
                return false
            }
        }
    }

    function onSimulEOLPushed() {
        const res = doCheckLoggedIn()
        setSimulDlgVisible(res)
        if (res)
            setIsEol(true)
    }

    function onSimulPVPushed() {
        const res = doCheckLoggedIn()
        setSimulDlgVisible(res)
        if (res)
            setIsEol(false)
    }

    function onAboutPushed() {
        setAboutDlgVisible(true)
    }

    function onAuthDlgPushed() {
        setSimulDlgVisible(false)
        setAuthDlgVisible(true)
    }

    return (
        <UIHorTopToolBarDiv pixelsz={38}>
            <UIHorToolBarDiv>
                <label style={{paddingLeft: "1rem",fontSize: 16, width: 200 }}>
                    {trl(SIM_KEY)}
                </label>
                <Divider/>
                <ButtonGroup fill={true} style={{ maxWidth: 300 }}>
                    <Button style={{ width: 150 }}
                        intent={Intent.PRIMARY}
                        text={trl([EOL_KEY])}
                        active={isEol && isSimulDlgVisible}
                        onClick={() => onSimulEOLPushed()}
                    />
                    <Button style={{ width: 150 }}
                        intent={Intent.WARNING}
                        text={trl([PV_KEY])}
                        active={!isEol && isSimulDlgVisible}
                        onClick={() => onSimulPVPushed()}
                    />
                </ButtonGroup>
            </UIHorToolBarDiv>
            {isAuthenticated &&
                <UISimulParamsDlg
                    marginLeft={"-450px"}
                    marginTop={"45px"}
                    isVisible={isSimulDlgVisible}
                    onClose={() => setSimulDlgVisible(false)}
                    isEol={isEol}
                    {...props}
                />
            }
            <UIProgressBar
                margin={"10px"}
                value={progress} 
                barHeight={"50%"} 
                runningIntent={isEol? Intent.PRIMARY : Intent.WARNING}
            />
            <UISpacerDiv/>
            <Divider/>
            <UIBarButton 
                icon={theme.isDark() ? "flash" : "moon"} 
                tooltip={`Switch to ${theme.getOppositeState()}`} 
                onClick={() => props.onThemeToggle() }/>
            <UIBarButton 
                icon={isAuthenticated ?  "user" :"blocked-person" }
                intent={isAuthenticated ? Intent.SUCCESS : Intent.DANGER}
                tooltip={isAuthenticated ? "logout" : "login"} 
                onClick={() => onAuthDlgPushed()}/>
            <UIBarButton 
                icon="help" 
                tooltip="About" 
                onClick={() => onAboutPushed()}/>
            <UIAuthDialog
                useAuthStore={useAuthStore}
                isVisible={isAuthDlgVisible}
                onClose={() => setAuthDlgVisible(false)}
            />
            <UIAboutDlg 
                isVisible={isAboutDlgVisible}
                onClose={() => setAboutDlgVisible(false)}
            />
            <UIBarButton
                icon={language.isFrench() ? FRIcon : GBIcon} 
                tooltip={`Switch to ${language.getOppositeState()}`} 
                onClick={() => props.onLanguageToggle() }
            />
        </UIHorTopToolBarDiv>
    )
}

