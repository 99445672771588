import { TokenRequest } from "../Base/TokenRequest"

const url = "/EOLE/Turbines"

export let WIND_TURBINE_LIST = []   
export let WIND_TURBINE_TYPE_LIST = [] 

const fetchTurbineList = async () => {
    try {
        WIND_TURBINE_TYPE_LIST = await TokenRequest.fetch(url,null,"GET")
        console.log(WIND_TURBINE_LIST)
        WIND_TURBINE_LIST = Object.keys(WIND_TURBINE_TYPE_LIST)
    } catch (error) {
        console.error("Error fetching turbine list:", error)
        throw error
    }
}
  
export { fetchTurbineList }
  