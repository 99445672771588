import { TokenRequest } from "../Base/TokenRequest"
import { globalParams, NB_CPU } from "./GlobalParams"

const url_to_backend = globalParams.backendBaseUrl

export class SimulRequest{
    constructor(payload) {
        this._url = url_to_backend
        this._payload = payload 
    }

    getSimulTypePah(iseol,issingle) {
        return iseol ? (issingle ? "/EOLE": "/EOLE_MULTI") 
            : (issingle ? "/PV" : "/PV_MULTI")
    }

    async execute(iseol,issingle) {
        let payload = issingle ? this._payload : { "sim_params" : this._payload, [NB_CPU]: globalParams.nbCPU }
        console.log(payload)
        return TokenRequest.fetch( this.getSimulTypePah(iseol,issingle), payload, "POST")
    }   
}
