import {SIMUL_PARAMS_EOL, SIMUL_PARAMS_EOL_MIN,SIMUL_PARAMS_EOL_MAX, SIMUL_PARAMS_EOL_VAL_TYPE,WIND_TURBINE,WIND_TURBINE_TYPE} from "./SimulParamsEOL"
import {SIMUL_PARAMS_PV,SIMUL_PARAMS_PV_MIN,SIMUL_PARAMS_PV_MAX, SIMUL_PARAMS_PV_VAL_TYPE, SOLAR_PANEL, SOLAR_PANEL_LIST, TRACKER} from "./SimulParamsPV"
import { WIND_TURBINE_LIST, WIND_TURBINE_TYPE_LIST } from "./SimulEOLTurbine"
import {ensureValInside,getKeyByValue} from "../Base/Utils"
import { UserHistory } from "../Base/UserHistory"

const EOL_PARAMS_STORAGE_KEY = "EOLSimulParams"
const PV_PARAMS_STORAGE_KEY = "PVSimulParams"


export default class SimulParams {

    constructor() {
        this._eol_params = { ...SIMUL_PARAMS_EOL }
        this._pv_params = { ...SIMUL_PARAMS_PV }

        this._eol_userHistory = new UserHistory(EOL_PARAMS_STORAGE_KEY)
        this._pv_userHistory = new UserHistory(PV_PARAMS_STORAGE_KEY)
        
        Object.seal(this._eol_params)
        Object.seal(this._pv_params)

        // this.load()
    }

    setParams(params,iseol) {
        if (iseol) 
            this._eol_params = {...params}
        else
            this._pv_params = {...params}
    }

    loadVals(myparvals,parvals) {
        if (!parvals) 
            return

        Object.keys(myparvals).forEach(k => { 
            if (parvals[k])
                myparvals[k] = parvals[k]
        })
    }

    ensureValidEOL() {
        const min_vals = SIMUL_PARAMS_EOL_MIN
        const max_vals = SIMUL_PARAMS_EOL_MAX
        const types = SIMUL_PARAMS_EOL_VAL_TYPE    
        Object.keys(this._eol_params).forEach(k => {
            if (types[k] === Number) {
                this._eol_params[k] = ensureValInside( Number(this._eol_params[k]), min_vals[k], max_vals[k] )
            }
        })

        if ( !(Object.values(WIND_TURBINE_LIST)).includes(this._eol_params[WIND_TURBINE]) ) {
            console.log("turbine not found ",this._eol_params[WIND_TURBINE])
            this._eol_params[WIND_TURBINE] = Object.values(WIND_TURBINE_LIST)[0]
        }
        
        const windTurbine = this._eol_params[WIND_TURBINE]
        const keyTurbine = WIND_TURBINE_TYPE_LIST[getKeyByValue(WIND_TURBINE_LIST,windTurbine)]
        const all_types = Object.values(keyTurbine)
        if ( !(all_types.includes(this._eol_params[WIND_TURBINE_TYPE])) ) {
            console.log("turbine type not found",this._eol_params[WIND_TURBINE_TYPE])
            this._eol_params[WIND_TURBINE_TYPE] = all_types[0]
        }
    }

    ensureValidPV() {
        const min_vals = SIMUL_PARAMS_PV_MIN
        const max_vals = SIMUL_PARAMS_PV_MAX
        const types = SIMUL_PARAMS_PV_VAL_TYPE
        Object.keys(this._pv_params).forEach(k => {
            if (types[k] === Number) {
                this._pv_params[k] = ensureValInside( Number(this._pv_params[k]), min_vals[k], max_vals[k] )
            }
        })
        if ( this._pv_params[SOLAR_PANEL] ) {
            if ( !(this._pv_params[SOLAR_PANEL] in SOLAR_PANEL_LIST) ) {
                console.log("solar panel not found", this._pv_params[SOLAR_PANEL] )
                this._pv_params[SOLAR_PANEL] = Object.values(SOLAR_PANEL_LIST)[0]
            }
        }
        if ( typeof this._pv_params[TRACKER] !== "boolean" ) {
            this._pv_params[TRACKER] = false 
        }
    }

    ensureValid() {
        this.ensureValidEOL()
        this.ensureValidPV()
    }

    load() {
        const eolpar = this._eol_userHistory.get(EOL_PARAMS_STORAGE_KEY)
        this.loadVals(this._eol_params,eolpar)
      
        const pvpar = this._pv_userHistory.get(PV_PARAMS_STORAGE_KEY)
        this.loadVals(this._pv_params,pvpar)

        this.ensureValid()
    }
    
    save() {
        this._eol_userHistory.set(EOL_PARAMS_STORAGE_KEY,this._eol_params)
        this._pv_userHistory.set(PV_PARAMS_STORAGE_KEY,this._pv_params)
    }

    eolParams() {
        return this._eol_params
    }

    pvParams() {
        return this._pv_params
    }
}
