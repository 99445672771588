import Papa from "papaparse"

export function parseCSV(csv_file) {
    return new Promise((resolve, reject) => {
        console.log(csv_file)
        Papa.parse(csv_file, {
            delimiter: ";",
            download: true,
            skipEmptyLines: true,
            header: true,
            dynamicTyping: true,
            trim: true,
            complete: (result) => {
                resolve(result.data)
            },
            error: (err) => {
                console.error("error",err.message)
                reject(err)
            },
        })
    })
}
