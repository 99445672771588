import { UserHistory } from "../Base/UserHistory"
import { defJEDIKeyLbl, trl } from "../Base/Language"
import {Buffer} from "buffer"
import config from "../Files/config.json"


const TOKEN_KEY = defJEDIKeyLbl("Token", ["Aucun jeton récupéré. Veuillez vérifier la connexion", "No token retrieved, please check your connection"])

const base_url = config ? config.auth_base_url : "http://localhost:3000/"
const auth_path = config ? config.auth_path : "authenticate"

export default class TokenFetcher {

    constructor() {
        this._username = null
        this._token = null

        this._history = new UserHistory(TOKEN_KEY)
        this.load()
    }

    load() {
        this._token = this._history.get("Token")
        this._username = this._history.get("Username")
    }

    save() {
        this._history.set("Token", this._token)
        this._history.set("Username", this._username)
    }

    getUsername() {
        return this._username
    }

    getToken() {
        return this._token
    }

    async request(url, params) {
        const authString = `${params.user}:${params.password}`
        const encodedAuthString = Buffer.from(authString).toString("base64") // Encode the auth string in base64
        const authHeader = `Basic ${encodedAuthString}`

        return fetch(url, {
            method: "POST",
            body: JSON.stringify(params),
            headers: {
                "Content-Type": "application/json",
                "Authorization": authHeader
            },
        }).then(r => {
            if (r.ok) {
                return r.json()
            }
            else
                throw new Error(trl(TOKEN_KEY))
        })
    }

    async init() {
        const url = base_url + auth_path + "/validity?token=" + this._token
        return this._token && this._username ?
            this.request(url, {}).then(r => r.isvalid) :
            Promise.resolve(false)
    }

    async fetch(username, pwd, isAgent) {
        const url = base_url + auth_path
        const params = {
            user: username,
            password: pwd,
            typeUser: isAgent ? "SESAME" : "AUTRE"
        }
        return this.request(url, params)
            .then(tok => {
                this._username = username
                this._token = tok.token
                this.save()
            })
    }

    erase() {
        this._token = null
        this._username = null
        this.save()
    }
}