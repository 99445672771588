import PubSub from "../Base/PubSub"
import { TokenRequest } from "../Base/TokenRequest"

const url_to_backend = "/Progress?name="
const simul_time_interval = 10000
const simul_max_try = 1000

export class ProgressChecker{
    constructor(payload) {
        this._url = url_to_backend
        this._payload = payload
        this._isactive = false
        this._progress = 0
        this._status = {}
    }

    start(fnm) {
        this._filename = fnm
        this._isactive = true
        this._progress = 0
        this._status = {}
        PubSub.publishProgressChanged(0)
        this.run()
    }

    stop() {
        this._isactive = false
        clearInterval(this._interval)
    }

    async run() {
        let time = 0
        this._interval = setInterval(async ()=> { 
            if ( !this._isactive )
                return

            if (time <= simul_max_try) { 
                const res = await this.execute()
                console.log("prog",res)
                if ( res && res.status !== this._status ) {
                    this._status = res.status
                    PubSub.publishSimulStatusChanged(this._status)
                }
                if ( res && res.progress > this._progress ) {
                    this._progress = res.progress
                    PubSub.publishProgressChanged(this._progress)
                    //TODO fix dirty comparison backend-side
                    if (this._progress >= 99.99 && this._progress <= 100.01 ) {
                        this.stop()
                    }
                }
                time++
            }
        }, simul_time_interval)
    }

    async execute() {
        const url = this._url + this._filename
        console.log(`checking for ${url}`)
        return TokenRequest.fetch( url, {}, "GET" )
    }
}

