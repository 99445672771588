export class UserHistory {

    constructor(storage_key) {
        this.storage_key = "jedi." + storage_key
        this.history = {}
        this.load()
    }

    load() {
        try {
            const storedHistory = JSON.parse(window.localStorage.getItem(this.storage_key))
            if (storedHistory && storedHistory instanceof Object) {
                this.history = storedHistory
                return true
            }
            return false
        } catch (error) {
            console.warn("Failed to load" + this.storage_key + "history from localStorage", error)
        }
    }

    set(key, par) {
        if ( !this.history )
            return

        this.history[key] = par
        try {
            window.localStorage.setItem(this.storage_key, JSON.stringify(this.history))
        } catch (error) {
            console.warn("Failed to save" + key +"history to localStorage", error)
        }
    }

    get(key) {
        return this.history ? this.history[key] : null
    }
}
