import React from "react"
import {Keys} from "@blueprintjs/core"
import { trl } from "../Base/Language"
import {LONGITUDE,LATITUDE} from "../Core/SimulParamsBase"
import {UIGridForm,UIHDiv,UIMargin,UISpinBox} from "@edf-r38/react-components"

export function UILatLonForm(props) {
    const {onLonChange, onLatChange, latlon, boundingbox} = { ...props }

    const onMyLonChange = (x,xstr) => {
        onLonChange(xstr)
    }

    const onMyLatChange = (y,ystr) => {
        onLatChange(ystr)
    }

    const handleLonConfirm = (xstr) => {
        let x = Number(xstr)
        if ( !isNaN(x) && !boundingbox.isXInside( x ) ) {
            x = boundingbox.getNearestXBorder(x)
            xstr=x   
        }
        onLonChange(xstr)
    }

    const handleLatConfirm = (ystr) => {
        let y = Number(ystr)
        if ( !isNaN(y) && !boundingbox.isYInside( y ) ) {
            y = boundingbox.getNearestYBorder(y)
            ystr=y
        }
        onLatChange(ystr)
    }

    const handleConfirm = (val,islon) => {
        if (islon) 
            handleLonConfirm(val)
        else
            handleLatConfirm(val)
    }

    const handleBlur = (e,islon) => {
        handleConfirm(e.target.value,islon)
    }

    const handleKeyDown = (e,islon) => {
        if (e.keyCode === Keys.ENTER) {
            handleConfirm(e.target.value,islon)
        }
    }

    return (
        <>
            <UIGridForm.Label>{"Position"}</UIGridForm.Label>
            <UIHDiv>
                <UIMargin> <UIGridForm.Label>{trl(LONGITUDE)}</UIGridForm.Label> </UIMargin>
                <UISpinBox
                    paramKey={""}
                    min={boundingbox.left()}
                    max={boundingbox.right()}
                    minorStepSize={0.0000001}
                    value={latlon[1]}   
                    minimal={true}
                    style={{width: "60px"}}
                    onKeyDown={e=>handleKeyDown(e,true)}
                    onBlur={e=>handleBlur(e,true)}
                    onChange={onMyLonChange}/>
                <UIMargin> <UIGridForm.Label>{trl(LATITUDE)}</UIGridForm.Label> </UIMargin>
                <UISpinBox
                    paramKey={""}
                    min={boundingbox.bottom()}
                    max={boundingbox.top()}
                    minorStepSize={0.0000001}
                    value={latlon[0]}
                    minimal={true}
                    style={{width: "60px"}}
                    onKeyDown={e=>handleKeyDown(e,false)}
                    onBlur={e=>handleBlur(e,false)}
                    onChange={onMyLatChange}/>
            </UIHDiv>
        </>
    )
}