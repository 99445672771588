import React from "react"
import { Intent, Icon } from "@blueprintjs/core"
import { Table2, Cell, Column } from "@blueprintjs/table"
import "@blueprintjs/table/lib/css/table.css"
import "@blueprintjs/core/lib/css/blueprint.css"

import {SIMUL_STATUS} from "../Core/SimulParamsBase"

const getStatusIcon = (s) => {
    switch (s) {
    case SIMUL_STATUS.NOT_STARTED:
        return <Icon icon="circle" intent={Intent.NONE} />
    case SIMUL_STATUS.STARTED:
        return <Icon icon="play" intent={Intent.WARNING} />
    case SIMUL_STATUS.FINISHED:
        return <Icon icon="tick" intent={Intent.SUCCESS} />
    case SIMUL_STATUS.FAILED:
        return <Icon icon="cross" intent={Intent.DANGER} />
    default:
        return null
    }
}

export const UIMultiSimulTable = ({ data, simstatus }) => {
    if (!data || data.length === 0 || !simstatus || Object.keys(simstatus).length === 0) {
        return <></>
    }

    const keys = Object.keys(data[0])
    const columnWidths = Array.from({ length: keys.length + 1 }, (_, idx) => (idx === 0 ? 35 : 70))
    let columns = [
        <Column
            key="status"
            name=""
            cellRenderer={(rowIdx)=><Cell>{getStatusIcon(simstatus[data[rowIdx].nom])}</Cell>}
        />, ...keys.map((key, index) => (
            <Column
                key={index}
                name={key}
                cellRenderer={
                    (rowIndex) => <Cell>{data[rowIndex][key]}</Cell>
                }
            />
        ))]

    return (
        <div style={{maxHeight:"500px",overflowY: "auto"}}>
            <Table2 numRows={data.length}
                enableRowHeader={false}
                enableColumnReordering={false}
                enableFocusedCell={true}
                columnWidths={columnWidths}
                cellRendererDependencies={[simstatus]}
            >
                {columns}
            </Table2>
        </div>
    )
}
