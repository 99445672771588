import React, {useEffect,useState} from "react"

import {parseCSV} from "../Base/CSVUtils.js"
import PubSub from "../Base/PubSub.js"
import {trl} from "../Base/Language.js"
import {globalParams,NB_CPU} from "../Core/GlobalParams.js"
import {genSimulName,NAME,SIMUL_STATUS} from "../Core/SimulParamsBase.js"
import {UIHCenteredDiv,UIMargin,UISpinBox,UIVDiv} from "@edf-r38/react-components"
import {UIMultiSimulTable} from "./UIMultiSimulTable.js"
import {AnchorButton,Divider,FileInput} from "@blueprintjs/core"
import "@blueprintjs/core/lib/css/blueprint.css"

export function UIMultiSiteBaseForm({iseol,onSetChange}) {
    const [filename,setFileName] = useState("Select file...")
    const [paramsset,setParamsSet] = useState([])
    const [statusset,setStatusSet] = useState({})
    const [nbCPU,setNbCPU] = useState(globalParams.nbCPU)

    const example_file = iseol ? "/liste_sites_eol.csv": "/liste_sites_pv.csv"
    
    const resetStatus = () => {
        setStatusSet( oldstatus => {
            const newstates = {...oldstatus}
            Object.keys(oldstatus).forEach(key => {
                newstates[key] = SIMUL_STATUS.NOT_STARTED
            })
            return newstates
        })
    }

    useEffect(()=>{
        const onstatuschanged = PubSub.onSimulStatusChanged(v => {
            setStatusSet( oldstatus => ({...oldstatus,...v}))
        } )
        const onsimulstarted = PubSub.onSimulationStarted( () => {
            resetStatus()
        })
        const onsimulstopped = PubSub.onSimulationStopped( () => {
            resetStatus()
        })
        return () => {
            PubSub.unsubscribe(onstatuschanged)
            PubSub.unsubscribe(onsimulstarted)
            PubSub.unsubscribe(onsimulstopped)
        }
    },[])


    async function handleInputChange(evt) {
        const fnms = evt.target.files
        if ( fnms.length ===0 ) {
            alert("No valid file selected")
        }
        else {
            const file = fnms[0]
            setFileName(file.name)
            let params_table = await parseCSV(file)
            params_table = params_table.map(
                (p,idx) => ({...p, [NAME]: genSimulName(iseol)+"_"+ idx}))
            const simstats = params_table.reduce((acc, p) => {
                acc[p[NAME]] = SIMUL_STATUS.NOT_STARTED
                return acc
            }, {})
                
            onSetChange(structuredClone(params_table))
            setParamsSet(params_table)
            setStatusSet(simstats)
            console.log(simstats)
        }
    }

    function onNbCPUChanged(v) {
        globalParams.nbCPU = v
        setNbCPU(v)
    }

    return (
        <UIVDiv>
            <UIHCenteredDiv>
                <FileInput 
                    onInputChange={handleInputChange} 
                    text={filename}
                    style={{minWidth:"350px"}}
                />
                { typeof process !== "undefined" && process ?
                    <AnchorButton 
                        href={process.env.PUBLIC_URL + example_file} 
                        style={{ maxWidth: "120px", marginLeft: "5px" }}
                    >
                        Example
                    </AnchorButton>
                    : <></>
                }
            </UIHCenteredDiv>
            <UIMargin/>
            <UIMultiSimulTable data={paramsset} simstatus={statusset}/>
            <UIMargin/>
            <Divider/>
            <UIHCenteredDiv>
                <UISpinBox                                     
                    style={{maxWidth:"60px"}}
                    paramKey={trl(NB_CPU)} 
                    value={nbCPU}
                    onChange={(v,) => onNbCPUChanged(v)}
                    stepSize={1}
                    minorStepSize={1}
                    allowNumericCharactersOnly={true}
                />
            </UIHCenteredDiv>
        </UIVDiv>    
    )
}
