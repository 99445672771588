import { UserHistory } from "./UserHistory"

const THEME_STORAGE_KEY = "theme"

export const DARK_THEME= "dark"
export const LIGHT_THEME = "light"

const THEME_COLORS = {
    [DARK_THEME]: "#394b59",
    [LIGHT_THEME]: "#ebf1f5"
}

export default class Theme {
    constructor() {
        this._state = DARK_THEME
        this._userHistory = new UserHistory(THEME_STORAGE_KEY)
        this.load()
    }
   
    isDark() {
        return this.getState() === DARK_THEME
    }

    getOppositeState() {
        return this.isDark() ? LIGHT_THEME : DARK_THEME
    }

    getColor() {
        return THEME_COLORS[this._state]
    }

    getState() {
        return this._state
    }

    setState(state) {
        if (state) {
            this._state = state
            this.save()
        }
    }

    load() {
        const state = this._userHistory.get(THEME_STORAGE_KEY)
        if (state)
            this._state = state
    }
    
    save() {
        this._userHistory.set(THEME_STORAGE_KEY, this._state)
    }
}

export const theme = new Theme()